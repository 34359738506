import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import { StaticImage } from 'gatsby-plugin-image'
import s1 from '../images/pragicts-software-development-process.png'
const WebsiteDeliveryScope = () => {
    return (
        <Layout pageTitle="Website Development Process" og_desc="This document outlines the process PragICTS follows for designing and implementing an online presence (website). It aims to provide all stakeholders with clear visibility into the involved steps, functions, and responsibilities essential for a successful execution.">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>WEBSITE DEVELOPMENT  <br/>
<span> PROCESS</span></h2>
                                <p></p>
                            
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>PROCESS</div>
                        <div className="container main-about">
                          


                            
                
                            
                           <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP001:&nbsp;&nbsp;Client Request for Website Developmen</span><br/>Initial discussions and presentations will clarify the client's business context, goals, and analysis of any existing website. We will explore how an effective online presence can contribute to the client’s success.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP002:&nbsp;&nbsp;Proposition Compilation</span><br/>This phase includes compiling, reviewing, and obtaining approval for the proposal from PragICTS leadership before submission.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP003:&nbsp;&nbsp;Proposition Approval by Client</span><br/>We will present the proposal to the client, incorporating reviews, revisions, and finalization, leading to client approval to move forward with the project.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP004:&nbsp;&nbsp;Sharing the Website Development Process</span><br/>We will share this document with the client to ensure clarity on the complete development process and responsibilities, enabling effective participation. It is the client’s responsibility to review this document to fully understand their commitments.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP005:&nbsp;&nbsp;Content Capture Questionnaire</span><br/>The Content Capture Questionnaire <a href='https://websites.softwaredevelopment.pragicts.com/content-capture-questionnaire/' target='_blank'>(https://websites.softwaredevelopment.pragicts.com/content-capture-questionnaire/)</a> will be shared with the client to gather the necessary content for the website. PragICTS will assist the client throughout this process.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP006:&nbsp;&nbsp;Data Compilation for Questionnaire</span><br/>The client must provide accurate and complete information as outlined in the questionnaire. This data must be reviewed and approved by the client’s management before being shared with PragICTS.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP007:&nbsp;&nbsp;Website Structure, Layout, and Content Compilation</span><br/>Once the content points are approved by the client, PragICTS will compile the website structure, layout, and content for client finalization.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP008:&nbsp;&nbsp;Website Concept Design</span><br/>We will develop the website concept design for client approval, incorporating the compiled content.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP009:&nbsp;&nbsp;Website Concept Presentation</span><br/>The finalized concept will be presented to the client for approval. Once approved, the website will be completed by PragICTS for a final review. Post-approval, only cosmetic changes will be allowed.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP010:&nbsp;&nbsp;Image Optimization</span><br/>We will optimize images for improved performance and user experience.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP011:&nbsp;&nbsp;SEO Implementation</span><br/>On-site and on-page SEO measures, including image meta tags and sitemap generation, will be implemented.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP012:&nbsp;&nbsp;Open Graph Implementation</span><br/>We will implement Open Graph for enhanced social media sharing across all website pages.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP013:&nbsp;&nbsp;Website Launch</span><br/>The website will be launched by PragICTS once it receives client approval.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP014:&nbsp;&nbsp;Google Business Registration</span><br/>If the client’s business is not registered with Google, we will facilitate the registration process.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP015:&nbsp;&nbsp;Google Analytics Integration</span><br/>We will assist in integrating Google Analytics with the client’s existing website if not already done.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDP016:&nbsp;&nbsp;Social Platforms Integration</span><br/>We will implement, brand, and integrate the necessary social media platforms with the website as required.</h6>
                          </p>
                          <p><h6 style={{fontSize: '15px',paddingTop: '15px'}}><span style={{color: 'black',fontWeight:'bold'}}>ANCILLARY FUNCTION&nbsp;&nbsp;</span><br/><span style={{fontSize: '13px'}}>Ancillary functions will be executed based on the client's specific scope and needs.</span></h6>
                          </p>
                       
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDPA001:&nbsp;&nbsp;Branding</span><br/>If included in the approved scope, PragICTS will manage the branding or rebranding of the corporate identity, including logo design, tagline creation, and business stationery. This will be executed by our affiliate, FlareUp Digital <a href='https://flareup.digital' target='_blank'>(https://flareup.digital)</a>.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDPA002:&nbsp;&nbsp;Domain Name Research and Procurement</span><br/>We will assist the client in researching and procuring domain names as needed.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDPA003:&nbsp;&nbsp;Corporate Email and Communication Implementation</span><br/>We will facilitate the implementation and personalization of corporate email services if required.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDPA004:&nbsp;&nbsp;Research and Procurement of Images and Videos</span><br/>If included in the project scope, PragICTS will do the required due diligence for the research and procurement of the required images and videos from digital sources.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDPA005:&nbsp;&nbsp;Digital Photography</span><br/>If included in the project scope, PragICTS will handle the photography needs for the website, executed by FlareUp Digital <a href='https://flareup.digital' target='_blank'>(https://flareup.digital)</a>.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WDPA006:&nbsp;&nbsp;Digital Videography</span><br/>Similarly, if required, we will create the necessary videos for the website, managed by FlareUp Digital <a href='https://flareup.digital' target='_blank'>(https://flareup.digital)</a>.</h6>
                          </p>
                         
                          



<div>










</div> 





















                        </div>
                     
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                        
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default WebsiteDeliveryScope
